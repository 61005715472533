.myformuser{
    
    margin-left: 15%;
    width: 80%;
}
.property-input{
    width: 25%;
    padding: 0 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    height: 45px;
    margin: 3%;
}
input.update-input{
    width: 25%;
    padding: 0 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    height: 45px;
    margin: 3%;
}
select.update-input{
    width: 25%;
    padding: 0 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    height: 45px;
    margin: 3%;
}
.butoni_update_user{
    border: none;
    padding: 1% 3%;
    margin: 2%;
    text-decoration: none;
    background-color: #567189;
    color: white;
    text-transform: uppercase;
    font-family: 17px;
    letter-spacing: 2px;
}