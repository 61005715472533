@media screen and (max-width: 400px) and (min-width: 300px) {
    .single-property-title{
        padding: 10%;
    }
    .bg-property{
        width: 100%;
        height: 60vh;
        text-align: center;
        background-image: url(../../images/images1.png);
        filter: brightness(70%);
        background-position: 25% 40%;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin-top: 10%;
        overflow: hidden;
        color: white;
 }
    .main-description p{
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
    }
    button.hire-button-agent {
        width: 27%;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: #8db8c5;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing:1px;
        margin: 3% auto;
        border: 2px solid white;
        display: flex;
        font-size: 14px;
    }
    .agent-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: 4%;
        background-color: white;
        border-radius: 5px;
     }
     .main-description{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         background-color: white;
         border-radius: 5px;
      }
      .other-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 5% auto;
         background-color: white;
         border-radius: 5px;
     }
     .similar-properties{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin: 3% auto;
        background-color: white;
        border-radius: 5px;
    }
    .similar-properties h4{
        padding: 5%;
    }
    button.whatsapp-button{
        margin-top: -30px;
     }
     button.fb-button{
         margin-top: -30px;
         background-color: transparent;
     }
     .main-description h4{
        padding: 3%;
     }
     div.swiper-slide > img{
  
        width:100%;
        height: 420px;
        object-fit: cover;
    
 }
 p.agent-username{
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
    padding: 2% 0%;
 }
     

}
@media screen and (max-width: 470px) and (min-width: 401px) {
    .single-property-title{
        padding: 7%;
    }
    .bg-property{
        width: 100%;
        height: 65vh;
        text-align: center;
        background-image: url(../../images/images1.png);
        filter: brightness(70%);
        background-position: 25% 40%;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin-top: 10%;
        overflow: hidden;
        color: white;
 }
    .main-description p{
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
    }
    button.hire-button-agent {
        width: 23%;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: #8db8c5;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing:1px;
        margin: 3% auto;
        border: 2px solid white;
        display: flex;
        font-size: 14px;
    }
    .agent-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: 4%;
        background-color: white;
        border-radius: 5px;
     }
     .main-description{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         background-color: white;
         border-radius: 5px;
      }
      .other-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 5% auto;
         background-color: white;
         border-radius: 5px;
     }
     .similar-properties{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin: 3% auto;
        background-color: white;
        border-radius: 5px;
    }
    .similar-properties h4{
        padding: 5%;
    }
    button.whatsapp-button{
        margin-top: -40px;
     }
     button.fb-button{
         margin-top: -40px;
         background-color: transparent;
     }
     .main-description h4{
        padding: 3%;
     }
     div.swiper-slide > img{
  
        width:100%;
        height: 420px;
        object-fit: cover;
    
 }
 p.agent-username{
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
    padding: 2% 0%;
 }
     
}
@media screen and (max-width: 570px) and (min-width: 471px) {
    .single-property-title{
        padding: 7%;
    }
    .bg-property{
        width: 100%;
        height: 65vh;
        text-align: center;
        background-image: url(../../images/images1.png);
        filter: brightness(70%);
        background-position: 25% 40%;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin-top: 10%;
        overflow: hidden;
        color: white;
 }
    .main-description p{
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
    }
    button.hire-button-agent {
        width: 19%;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: #8db8c5;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing:1px;
        margin: 3% auto;
        border: 2px solid white;
        display: flex;
        font-size: 14px;
    }
    .agent-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: 4%;
        background-color: white;
        border-radius: 5px;
     }
     .main-description{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         background-color: white;
         border-radius: 5px;
      }
      .other-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 5% auto;
         background-color: white;
         border-radius: 5px;
     }
     .similar-properties{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin: 3% auto;
        background-color: white;
        border-radius: 5px;
    }
    .similar-properties h4{
        padding: 4%;
    }
    button.whatsapp-button{
        margin-top: -42px;
     }
     button.fb-button{
         margin-top: -42px;
         background-color: transparent;
     }
     .main-description h4{
        padding: 3%;
     }
     div.swiper-slide > img{
  
        width:100%;
        height: 420px;
        object-fit: cover;
    
 }
 p.agent-username{
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
    padding: 2% 0%;
 }
     
}
@media screen and (max-width: 670px) and (min-width: 571px) {
    .single-property-title{
        padding: 7%;
    }
    .bg-property{
        width: 100%;
        height: 65vh;
        text-align: center;
        background-image: url(../../images/images1.png);
        filter: brightness(70%);
        background-position: 25% 40%;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin-top: 10%;
        overflow: hidden;
        color: white;
 }
    .main-description p{
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
    }
    button.hire-button-agent {
        width: 16%;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: #8db8c5;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing:1px;
        margin: 3% auto;
        border: 2px solid white;
        display: flex;
        font-size: 14px;
    }
    .agent-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: 4%;
        background-color: white;
        border-radius: 5px;
     }
     .main-description{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         background-color: white;
         border-radius: 5px;
      }
      .other-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 5% auto;
         background-color: white;
         border-radius: 5px;
     }
     .similar-properties{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin: 3% auto;
        background-color: white;
        border-radius: 5px;
    }
    .similar-properties h4{
        padding: 4%;
    }
    button.whatsapp-button{
        margin-top: -48px;
     }
     button.fb-button{
         margin-top: -48px;
         background-color: transparent;
     }
     .main-description h4{
        padding-top: 0%;
        padding-bottom: 3%;
        padding-left: 5%;
     }
     div.swiper-slide > img{
  
        width:100%;
        height: 420px;
        object-fit: cover;
    
 }
 p.agent-username{
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
    padding: 2% 0%;
 }
}
@media screen and (max-width: 767px) and (min-width: 671px) {
    .single-property-title{
        padding: 10%;
    }
    .bg-property{
        width: 100%;
        height: 65vh;
        text-align: center;
        background-image: url(../../images/images1.png);
        filter: brightness(70%);
        background-position: 25% 40%;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin-top: 10%;
        overflow: hidden;
        color: white;
 }
    .main-description p{
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
    }
    button.hire-button-agent {
        width: 16%;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: #8db8c5;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing:1px;
        margin: 3% auto;
        border: 2px solid white;
        display: flex;
        font-size: 14px;
    }
    .agent-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: 4%;
        background-color: white;
        border-radius: 5px;
     }
     .main-description{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         background-color: white;
         border-radius: 5px;
      }
      .other-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 5% auto;
         background-color: white;
         border-radius: 5px;
     }
     .similar-properties{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin: 3% auto;
        background-color: white;
        border-radius: 5px;
    }
    .similar-properties h4{
        padding: 4%;
    }
    button.whatsapp-button{
        margin-top: -48px;
     }
     button.fb-button{
         margin-top: -48px;
         background-color: transparent;
     }
     .main-description h4{
        padding-top: 0%;
        padding-bottom: 3%;
        text-align: left;
        padding-left: 5%;
     }
     div.swiper-slide > img{
        width: 70%;
        height: 420px;
        object-fit: cover;
    
    }
    p.agent-username{
        font-weight: 600;
        font-family: "Poppins", Sans-serif;
        padding: 2% 0%;
     }
}
@media screen and (max-width: 990px) and (min-width: 768px) {
    .single-property-title{
        padding: 6%;
    }
    .bg-property{
        width: 100%;
        height: 65vh;
        text-align: center;
        background-image: url(../../images/images1.png);
        filter: brightness(70%);
        background-position: 25% 40%;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin-top: 5%;
        overflow: hidden;
        color: white;
 }
    .main-description p{
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
    }
    button.hire-button-agent {
        width: 14%;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: #8db8c5;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing:1px;
        margin: 3% auto;
        border: 2px solid white;
        display: flex;
        font-size: 14px;
    }
    .agent-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: 4%;
        background-color: white;
        border-radius: 5px;
        width: 85%;
        margin: auto;
     }
     .main-description{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         background-color: white;
         border-radius: 5px;
         width: 85%;
      }
      .other-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         padding: 15px 30px;
         background-color: white;
         border-radius: 5px;
         width: 85%;
     }
     .similar-properties{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin: 3% auto;
        padding: 15px 30px;
        background-color: white;
        border-radius: 5px;
        width: 85%;
    }
    .similar-properties h4{
        padding: 4%;
    }
    button.whatsapp-button{
        margin-top: -48px;
     }
     button.fb-button{
         margin-top: -48px;
         background-color: transparent;
     }
     .main-description h4{
        padding-top: 0%;
        padding-bottom: 3%;
        text-align: left;
        padding-left: 5%;
     }
     div.swiper-slide > img{
        width: 70%;
        height: 420px;
        object-fit: cover;
    }
    p.agent-username{
        font-weight: 600;
        font-family: "Poppins", Sans-serif;
        padding: 2% 0%;
     }
}
@media screen and (min-width: 991px){
    .main-description{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
         margin: 3% auto;
         background-color: white;
         border-radius: 5px;
      }
      .other-information{
         box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          margin: 3% auto;
          padding: 15px 30px;
          background-color: white;
          border-radius: 5px;
      }
      .similar-properties{
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          margin: 3% auto;
          padding: 15px 30px;
          background-color: white;
          border-radius: 5px;
      }
      .agent-information{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: 4%;
        background-color: white;
        border-radius: 5px;
        margin-left: 5%;
     }
     .single-property-title{
        padding: 2%;
    }
    button.hire-button-agent {
        width: 18%;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: #8db8c5;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing:1px;
        margin: 3% auto;
        border: 2px solid white;
        display: flex;
        font-size: 14px;
    }
    .main-description p{
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
    }
    .similar-properties h4{
        padding: 4%;
    }
    button.whatsapp-button{
        margin-top: -50px;
     }
     button.fb-button{
         margin-top: -50px;
         background-color: transparent;
     }
     .main-description h4{
        padding-top: 0%;
        padding-bottom: 3%;
        text-align: left;
        padding-left: 5%;
     }
     div.swiper-slide > img{
        width: 70%;
        height: 420px;
        object-fit: cover;
    
    }
    p.agent-username{
        font-weight: 600;
        font-family: "Poppins", Sans-serif;
        padding: 2% 0%;
     }
}


.single-property-big-div{
    background-color: rgb(241, 241, 241);
}
.single-icon{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: 2px solid black;
    border-radius: 50%;
    transition: all 0.25s ease 0s;
    text-decoration: none;
    color: white;
    font-size: 20px;
    background-color: black;
    margin: auto;
    text-align: center;
}
  .single-property-listing {
    display: flex;
    justify-content: center;
  }
.rresht{
    margin: auto;
}
.email{
    display: flex;
}

 .other-information h4{
    padding: 3%;
 }

 .single-icon svg{
    margin: 0;
 }
 .agent-image{
    width: 100%;
    height: 360px;
    object-fit: contain;
 }
 .form-wrapper-agent>input {
    width: 70%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px px;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
    margin-top: 10px;
}

.form-wrapper-agent>textarea {
    width: 70%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
    transition: all .3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: .1px;
    height: 155px;
}
.form-wrapper-agent>input:focus {
    border-color: #8db8c5;
}

.form-wrapper-agent>textarea:focus {
    border-color: #8db8c5;
}
.contact-us-agent{
    text-align: center;
    padding-bottom: 5%;
}

.agent-data > p >span{
    padding-right:3%;
    font-family: "Poppins", Sans-serif;
 } 
 p.agent-username{
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
    padding: 2% 0%;
 }
 p.first-property{
    padding-right: 5%;
 }
 .share-links{
    display: flex;
 }
 .share-paragraph{
   font-weight: bold;
   padding-top: 3%;
   font-size: 16px;
 }
 .formatted-text{
    white-space: pre-wrap;
    text-align: left;
 }

