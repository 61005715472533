.log_out{
    text-align: center;
  padding-top: 3%;
  }
  
  
  .yes_but{
      background-color: #c42424; /* Green */
      border: none;
      color: white;
      padding: 10px 22px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin-right:10px
  
  }
  
  
  .no_but{
      background-color: #208355; /* Green */
      border: none;
      color: white;
      padding: 10px 22px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
  
  }
  @media screen and (max-width: 991px) {
    [data-aos-delay] {
      transition-delay: 0s !important;
    }
  }