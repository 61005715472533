@media screen and (max-width: 460px) and (min-width: 300px) {

    #prona-container{
        margin-top: 10%;
    }
    .prona-type{
        padding: 8px;
        background-color: black;
        color: white;
        width: 30%;
        position: absolute;
        z-index: 1;
        margin-left: 9%;
        text-transform: capitalize;
    }
    .portfolio-image{
        width: 100%;
        height: 260px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .prona-icons svg{
        font-size: 16px;
    }
    div.card-title.h5{
        font-size: 20px;
    }
    .prona-button{
        margin-top: 8%;
        margin-bottom: 8%;
        padding: 2%;
        background-color: black;
        color: white;
        width: 50%;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-size: 13px
    }
    .prona-card.card{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12%;
        width: 80%;
    }
    span.prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
    .prona-card-body.card-body{
        padding: 3%;
    }
    .best-property-div{
        margin: 13% 0%;
      }
      .best-property-div > h1{
        font-family: "Poppins", Sans-serif;
        font-size: 27px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
    }
      .best-property-div > p{
          font-family: "Poppins", Sans-serif;
          text-transform: uppercase;
          font-size: 16px;
          color: black;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.6px;
      }
}
@media screen and (max-width: 600px) and (min-width: 461px) {
    #prona-container{
        margin-top: 8%;
    }
    .prona-type{
        padding: 8px;
        background-color: black;
        color: white;
        width: 30%;
        position: absolute;
        z-index: 1;
        margin-left: 14%;
        text-transform: capitalize;
    }
    .portfolio-image{
        width: 100%;
        height: 280px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .prona-icons svg{
        font-size: 17px;
    }
    div.card-title.h5{
        font-size: 20px;
    }
    .prona-button{
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 2%;
        background-color: black;
        color: white;
        width: 40%;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-size: 13px
    }
    .prona-card.card{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12%;
        width: 70%;
    }
    span.prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
    .prona-card-body.card-body{
        padding: 3%;
    }
    .best-property-div{
        margin: 13% 0%;
      }
      .best-property-div > h1{
        font-family: "Poppins", Sans-serif;
        font-size: 34px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
    }
      .best-property-div > p{
          font-family: "Poppins", Sans-serif;
          text-transform: uppercase;
          font-size: 18px;
          color: black;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.6px;
      }
}
@media screen and (max-width: 767px) and (min-width: 601px) {
    #prona-container{
        margin-top: 8%;
    }

    .prona-type{
        padding: 8px;
        background-color: black;
        color: white;
        width: 20%;
        position: absolute;
        z-index: 1;
        margin-left: 8%;
        text-transform: capitalize;
    }
    .portfolio-image{
        width: 100%;
        height: 280px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .prona-icons svg{
        font-size: 17px;
    }
    div.card-title.h5{
        font-size: 20px;
    }
    .prona-button{
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 2%;
        background-color: black;
        color: white;
        width: 40%;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-size: 13px
    }
    .prona-card.card{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12%;
        width: 80%;
    }
    span.prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
    .prona-card-body.card-body{
        padding: 3%;
    }
    .best-property-div{
        margin-top: 10%;
        margin-bottom: 8%;
        margin-left: auto;
        margin-right: auto;
      }
      .best-property-div > h1{
        font-family: "Poppins", Sans-serif;
        font-size: 38px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
    }
      .best-property-div > p{
          font-family: "Poppins", Sans-serif;
          text-transform: uppercase;
          font-size: 20px;
          color: black;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.6px;
      }

}
@media screen and (max-width: 900px) and (min-width: 768px) {
   
    #prona-container{
        margin-top: 8%;
    }
   
    .prona-type{
        padding: 8px;
        background-color: black;
        color: white;
        width: 15%;
        position: absolute;
        z-index: 1;
        margin-left: 0%;
        text-transform: capitalize;
    }
    .portfolio-image{
        width: 100%;
        height: 280px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .prona-icons svg{
        font-size: 17px;
    }
    div.card-title.h5{
        font-size: 20px;
    }
    .prona-button{
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 2%;
        background-color: black;
        color: white;
        width: 25%;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-size: 14px
    }
    .prona-card.card{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12%;
        width: 100%;
    }
    span.prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
    .prona-card-body.card-body{
        padding: 3%;
    }
}
@media screen and (max-width: 1000px) and (min-width: 901px) {
   
    #prona-container{
        margin-top: 5%;
    }
   
    .prona-type{
        padding: 8px;
        background-color: black;
        color: white;
        width: 15%;
        position: absolute;
        z-index: 1;
        margin-left: 0%;
        text-transform: capitalize;
    }
    .portfolio-image{
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .prona-icons svg{
        font-size: 18px;
    }
    div.card-title.h5{
        font-size: 22px;
    }
    .prona-button{
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 2%;
        background-color: black;
        color: white;
        width: 25%;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-size: 14px
    }
    .prona-card.card{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12%;
        width: 100%;
    }
    span.prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
    .prona-card-body.card-body{
        padding: 3%;
    }
    .best-property-div{
        margin-top: 8%;
        margin-bottom: 6%;
        margin-left: auto;
        margin-right: auto;
      }
      .best-property-div > h1{
        font-family: "Poppins", Sans-serif;
        font-size: 48px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
    }
      .best-property-div > p{
          font-family: "Poppins", Sans-serif;
          text-transform: uppercase;
          font-size: 22px;
          color: black;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.6px;
      }
}
@media screen and (min-width: 1001px){
   
    #prona-container{
        margin-top: 5%;
    }
    .prona-type{
        padding: 8px;
        background-color: black;
        color: white;
        width: 12%;
        position: absolute;
        z-index: 1;
        margin-left: 0%;
        text-transform: capitalize;
    }
    .portfolio-image{
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .prona-icons svg{
        font-size: 18px;
    }
    div.card-title.h5{
        font-size: 22px;
    }
    .prona-button{
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 1%;
        background-color: black;
        color: white;
        width: 19%;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-size: 14px
    }
    .prona-card.card{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2%;
        width: 100%;
    }
    span.prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
    .prona-card-body.card-body{
        padding: 3%;
    }
    .best-property-div{
        margin-top: 8%;
        margin-bottom: 6%;
        margin-left: auto;
        margin-right: auto;
      }
      .best-property-div > h1{
        font-family: "Poppins", Sans-serif;
        font-size: 48px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
    }
      .best-property-div > p{
          font-family: "Poppins", Sans-serif;
          text-transform: uppercase;
          font-size: 22px;
          color: black;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.6px;
      }
}
.portfolio-image:hover{
    transform: scale(1.3);
    transition: transform 1s;
}
div.image-part{
    overflow: hidden;
}
.prona-icons{
    margin-right: 10%;
}

.prona-button:hover{
    color: black;
    font-weight: 500;
    background-color: white;
}
.property-id{
    text-align: left;
    font-size: 17px;
    font-weight: 500;
}
