@import url('https://fonts.googleapis.com/css2?family=Belanosima&family=Dancing+Script&family=Titillium+Web:ital@1&display=swap');
@media screen and (max-width: 365px) and (min-width: 300px) {
    .bg {
      width: 100%;
      height: 90vh;
      text-align: center;
      background-image: url(../../images/bg3.png);
      /* background-attachment: fixed; */
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
    }
    button.filter-button{
      border: none;
      color: black;
      margin-left: 0%;
      margin-right: 10%;
      margin-top: -5px;
      background-color: transparent;
    }
    .property-search{
      background-color: white;
      border-radius: 5px;
      padding: 1%;
      margin: 5% auto;
    }
    .property-label{
      padding: 8px 16px 8px 16px;
        border-radius: 4px 4px 0 0;
        background: black;
        color: white;
        cursor: pointer
    }
    .property-list{
      display: flex;
      margin-left: -30px;
      list-style: none;
      gap: 8px;
      margin-top: 5%;
    margin-bottom: -2%;
    }
}
@media screen and (max-width: 475px) and (min-width: 366px) {
    .bg {
      width: 100%;
      height: 95vh;
      text-align: center;
      background-image: url(../../images/bg3.png);
      /* background-attachment: fixed; */
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
    }
    button.filter-button{
      border: none;
      color: black;
      margin-left: 0%;
      margin-right: 10%;
      margin-top: -5px;
      background-color: transparent;
    }
    .property-search{
      background-color: white;
      border-radius: 5px;
      padding: 1%;
      margin: 5% auto;
    }
    .property-label{
      padding: 8px 16px 8px 22px;
        border-radius: 4px 4px 0 0;
        background: black;
        color: white;
        cursor: pointer
    }
    .property-list{
      display: flex;
      list-style: none;
      gap: 8px;
      margin: 5% auto;
    }
}
@media screen and (max-width: 550px) and (min-width: 476px) {
    .bg {
      width: 100%;
      height: 100vh;
      text-align: center;
      background-image: url(../../images/bg3.png);
      /* background-attachment: fixed; */
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
    }
    button.filter-button{
      border: none;
      color: black;
      margin-left: 0%;
      margin-right: 10%;
      margin-top: -5px;
      background-color: transparent;
    }
    .property-search{
      background-color: white;
      border-radius: 5px;
      padding: 1%;
      margin: 5% auto;
    }
    .property-label{
      padding: 8px 16px 8px 22px;
        border-radius: 4px 4px 0 0;
        background: black;
        color: white;
        cursor: pointer
    }
    .property-list{
      display: flex;
      list-style: none;
      gap: 8px;
      margin: 5% auto;
    }
}
@media screen and (max-width: 640px) and (min-width: 551px) {
    .bg {
      width: 100%;
      height: 110vh;
      text-align: center;
      background-image: url(../../images/bg3.png);
      /* background-attachment: fixed; */
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
    }
    button.filter-button{
      border: none;
      color: black;
      margin-left: 1%;
      margin-right: 15%;
      margin-top: -5px;
      background-color: transparent;
    }
    .property-search{
      background-color: white;
      border-radius: 5px;
      padding: 1%;
      margin: 5% auto;
    }
    .property-label{
      padding: 8px 16px 8px 22px;
        border-radius: 4px 4px 0 0;
        background: black;
        color: white;
        cursor: pointer
    }
    .property-list{
      display: flex;
      list-style: none;
      gap: 8px;
      margin: 5% auto;
    }
}
@media screen and (max-width: 768px) and (min-width: 641px) {
    .bg {
      width: 100%;
      height: 110vh;
      text-align: center;
      background-image: url(../../images/bg3.png);
      /* background-attachment: fixed; */
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      background-position: 50%;
    }
    button.filter-button{
      border: none;
      color: black;
      margin-left: 1%;
      margin-right: 20%;
      margin-top: -5px;
      background-color: transparent;
    }
    .property-search{
      background-color: white;
      border-radius: 5px;
      padding: 1%;
      margin: 5% auto;
    }
    .property-label{
      padding: 8px 16px 8px 22px;
        border-radius: 4px 4px 0 0;
        background: black;
        color: white;
        cursor: pointer
    }
    .property-list{
      display: flex;
      list-style: none;
      gap: 8px;
      margin: 5% auto;
    }
}
@media screen and (max-width: 850px) and (min-width: 769px) {
  .bg {
    width: 100%;
    height: 110vh;
    text-align: center;
    background-image: url(../../images/bg3.png);
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
    background-position: 50%;
  }
  button.filter-button{
    border: none;
    color: black;
    margin-left: 1%;
    margin-right: 35%;
    margin-top: -5px;
    background-color: transparent;
  }
  .property-search{
    background-color: white;
    border-radius: 5px;
    padding: 1%;
    margin: 5% auto;
  }
  .property-label{
    padding: 8px 16px 8px 22px;
      border-radius: 4px 4px 0 0;
      background: black;
      color: white;
      cursor: pointer
  }
  .property-list{
    display: flex;
    list-style: none;
    gap: 8px;
    margin: 5% auto;
  }
}
@media screen and (max-width: 999px) and (min-width: 851px) {
    .bg {
      width: 100%;
      height: 110vh;
      text-align: center;
      background-image: url(../../images/bg3.png);
      /* background-attachment: fixed; */
      background-position: 25% 75%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;

    }
    button.filter-button{
      border: none;
      color: black;
      margin-left: 1%;
      margin-right: 35%;
      margin-top: -5px;
      background-color: transparent;
    }
    .property-search{
      background-color: white;
      border-radius: 5px;
      padding: 1%;
      margin: 5% auto;
    }
    .property-label{
      padding: 8px 16px 8px 22px;
        border-radius: 4px 4px 0 0;
        background: black;
        color: white;
        cursor: pointer
    }
    .property-list{
      display: flex;
      list-style: none;
      gap: 8px;
      margin: 5% auto;
    }
}
@media screen and (min-width: 1000px){
    .bg {
      width: 100%;
      height: 130vh;
      text-align: center;
      background-image: url(../../images/bg3.png);
      /* background-attachment: fixed; */
      background-position: 25% 60%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
    }
    .property-list{
      display: flex;
      margin-left: -30px;
      list-style: none;
      gap: 8px;
    } 
    .property-label{
      padding: 10px 20px 10px 25px;
      border-radius: 4px 4px 0 0;
      background: black;
      color: white;
      cursor: pointer
    }
    .property-search{
      background-color: white;
      border-radius: 5px;
      padding: 1%;
    }
    .filter-button{
      border: none;
      color: black;
      margin-left: 5%;
      margin-right: 30%;
      margin-top: -5px;
      background-color: transparent;
    } 
  }
  
.big-filter{
    width: 895px;
    z-index: 5;
    margin: auto;
}
.property-icon{
  color: grey;
  font-size: 20px;
}
.property-data {
  flex-direction: row;
    display: flex;
}
.property-data select {
  padding: 8px;
  font-size: 14px;
  border: none;
  margin-right: 10px;
  border-right: 1px solid #a5a3a3;
  width: 100%;
  font-size: 16px;
  background-color: white;
  color: black;
}
.property-data input {
  padding: 8px;
  font-size: 14px;
  border: none;
  margin-right: 10px;
  border-right: 1px solid #a5a3a3;
  width: 100%;
  font-size: 16px;
}

.form-button{
  padding: 7px 20px 7px 25px;
  border-radius: 4px 4px 0 0;
  background: black;
  color: white;
  cursor: pointer;
  font-size:16px;
}
.filtra-avancuar{
  margin: 4% 0%;

}
.property-data input:focus-visible {
  outline: none;
}
.property-data select:focus-visible {
  outline: none;
}
.property-data select::after {
padding: 5%;
}
.group-button{
  display: flex;
}