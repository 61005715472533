.div-button{

   
}
.shto_res{
    border: none;
    padding: 1% 3%;
    margin: 2%;
    text-decoration: none;
    background-color: #567189;
    color: white;
    text-transform: uppercase;
    font-family: 17px;
    letter-spacing: 2px;

}
.shiko_res{
    border: none;
    padding: 1% 3%;
    margin: 2%;
    text-decoration: none;
    background-color: #567189;
    color: white;
    text-transform: uppercase;
    font-family: 17px;
    letter-spacing: 2px;
}
.shto-button{
    padding: 5%;
}
.plus_sign .eye_sign{
    font-size: 16px;
    padding-left: 4px;
}