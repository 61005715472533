table.property-table{
    margin-left: 19%;
    border: 1px solid grey;
    padding-top: 5%;
}
table.property-table > thead > th{
    width: 200px;
}
table > thead > th{
    border: 1px solid grey;
}
table > tbody > tr > td{
    border: 1px solid grey;
}
.delete-listing-button{
    border:none;
    background-color: white;
    color: red;
    font-weight: 600;
}
.edit-listing-button{
    border:none;
    background-color: white;
    color: green;
    font-weight: 600;
    text-decoration: none;
}