@media screen and (max-width: 450px) and (min-width: 300px) {
    .about-image{
        width: 80%;
        height: 370px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 14px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
        padding-top: 15%;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 36px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 10%;
    }
    .about-description {
        color: #797979;
        font-size: 14px;
        line-height: 1.9em;
        margin-top: 8%;
        text-align: center;
    }
}
@media screen and (max-width: 530px) and (min-width: 451px) {
    .about-image{
        width: 75%;
        height: 430px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
        padding-top: 10%;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 36px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 8%;
    }
    .about-description {
        color: #797979;
        font-size: 15px;
        line-height: 1.9em;
        margin-top: 8%;
        text-align: center;
    }
}
@media screen and (max-width: 640px) and (min-width: 531px) {
    .about-image{
        width: 65%;
        height: 440px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
        padding-top: 10%;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 36px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 8%;
    }
    .about-description {
        color: #797979;
        font-size: 15px;
        line-height: 1.9em;
        margin-top: 8%;
        text-align: center;
    }
}
@media screen and (max-width: 767px) and (min-width: 641px) {
    .about-image{
        width: 70%;
        height: 450px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 18px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
        padding-top: 8%;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 40px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 6%;
    }
    .about-description {
        color: #797979;
        font-size: 15px;
        line-height: 1.9em;
        margin-top: 6%;
        text-align: center;
    }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
    .about-image{
        width: 90%;
        height: 450px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 30px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 10%;
    }
    .about-description {
        color: #797979;
        font-size: 14px;
        line-height: 1.9em;
        margin-top: 6%;
        text-align: justify;
    }
    .about-div{
        margin-top: 32%;
    }
}
@media screen and (max-width: 991px) and (min-width: 901px) {
    .about-image{
        width: 90%;
        height: 450px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 30px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 10%;
    }
    .about-description {
        color: #797979;
        font-size: 14px;
        line-height: 1.9em;
        margin-top: 6%;
        text-align: justify;
    }
    .about-div{
        margin-top: 32%;
    }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .about-image{
        width: 85%;
        height: 680px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 18px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 36px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 5%;
    }
    .about-description {
        color: #797979;
        font-size: 15px;
        line-height: 1.9em;
        margin-top: 6%;
        text-align: justify;
    }
    .about-description-div{
        margin-top: 4%;
    }
}
@media screen and (min-width: 1201px) {
    .about-image{
        width: 80%;
        height: 730px;
        object-fit: cover;
        border-radius: 2%;
    }
    .first-title{
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        font-size: 22px;
        color: black;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
    }
    .second-title{
        font-family: "Poppins", Sans-serif;
        font-size: 45px;
        color: black;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.6px;
        margin-top: 3%;
    }
    .about-description {
        color: #797979;
        font-size: 17px;
        line-height: 1.9em;
        margin-top: 6%;
        text-align: justify;
    }
    .about-description-div{
        margin-top: 5%;
    }
}
.about-button{

        padding: 1%;
        background-color: black;
        color: white;
        width: 30%;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-size: 13px;
       
        
}
.last-line{
    font-style: italic;
    font-weight: 600;
}

