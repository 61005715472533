@media screen and (max-width: 991px) {
    [data-aos-delay] {
      transition-delay: 0s !important;
    }
  }
  table.table.table-sm{
    margin-left: 25%;
    width: 70%;
  }
  a.edit_link{
text-decoration: none;
color: rgb(39, 133, 156);
  }
  .delete_btn{
    border: none;
    color: rgb(39, 133, 156);
    background-color:white
  }