.div-button{

   
}
.shto_res{
    border: none;
    padding: 1% 3%;
    margin: 2%;
    text-decoration: none;
    background-color: #567189;
    color: white;
    text-transform: uppercase;
    font-family: 17px;
    letter-spacing: 2px;

}

.shto-button{
    padding: 5%;
}
.plus_sign {
    font-size: 16px;
    padding-left: 4px;
}
.city-button {
    width: 10%;
    padding: 8px;
    border: none;
    border-radius: 5px;
    color:white;
    background-color: black;
}
.city-div > input{
    width: 20%;
    padding: 0 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    height: 45px;
    margin: 3%;
}
.area-div > input{
    width: 20%;
    padding: 0 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    height: 45px;
    margin: 3%;
}
.area-button {
    width: 10%;
    padding: 8px;
    border: none;
    border-radius: 5px;
    color:white;
    background-color: black;
}
.filter-error{
    color: red;
}