
@media screen and (max-width: 460px) and (min-width: 300px) {
  .prona-title {
      font-family: 'Lato', sans-serif;
      z-index: 1;
      font-size: 60px;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 8px;
      text-align: center;
      font-size: 27px;
  }
  .prona-subtitle {
      z-index: 2;
      text-align: center;
      margin-top: -47px;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
  }
  .prona-text{
      margin-bottom: 15%;
  }
}
@media screen and (max-width: 600px) and (min-width: 461px) {
  .prona-title {
      font-family: 'Lato', sans-serif;
      z-index: 1;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 8px;
      text-align: center;
      font-size: 30px;
      padding: 0% 10%
  }
  .prona-subtitle {
      z-index: 2;
      text-align: center;
      margin-top: -53px;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 22px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
  }
  .prona-text{
      margin-bottom: 13%;
  }
}
@media screen and (max-width: 767px) and (min-width: 601px) {
  .prona-title {
      font-family: 'Lato', sans-serif;
      z-index: 1;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 8px;
      text-align: center;
      font-size: 36px;
      padding: 0% 10%
  }
  .prona-subtitle {
      z-index: 2;
      text-align: center;
      margin-top: -36px;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
  }
  .prona-text{
      margin-bottom: 13%;
  }

}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .prona-title {
      font-family: 'Lato', sans-serif;
      z-index: 1;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 8px;
      text-align: center;
      font-size: 38px;
      padding: 0% 10%
  }
  .prona-subtitle {
      z-index: 2;
      text-align: center;
      margin-top: -40px;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
  }
  .prona-text{
      margin-bottom: 10%;
  }
}
@media screen and (max-width: 1000px) and (min-width: 901px) {
  .prona-title {
      font-family: 'Lato', sans-serif;
      z-index: 1;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 8px;
      text-align: center;
      font-size: 45px;
  }
  .prona-subtitle {
      z-index: 2;
      text-align: center;
      margin-top: -45px;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
  }
  .prona-text{
      margin-bottom: 5%;
  }
}
@media screen and (min-width: 1001px){
  .prona-title {
      font-family: 'Lato', sans-serif;
      z-index: 1;
      color: rgba(0, 0, 0, 0.06);
      font-weight: 900;
      letter-spacing: 8px;
      text-align: center;
      font-size: 45px;
  }
  .prona-subtitle {
      z-index: 2;
      text-align: center;
      margin-top: -45px;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
  }
  .prona-text{
      margin-bottom: 5%;
  }
}
.portfolio-image:hover{
  transform: scale(1.3);
  transition: transform 1s;
}
div.image-part{
  overflow: hidden;
}
.prona-icons{
  margin-right: 10%;
}

.prona-button:hover{
  color: black;
  font-weight: 500;
  background-color: white;
}
.property-id{
  text-align: left;
  font-size: 17px;
  font-weight: 500;
}


.alice-carousel__prev-btn{
  text-align: unset;
}
.alice-carousel__prev-btn-item{
font-size: 23px;
}
.alice-carousel__next-btn-item{
  font-size: 23px;
  }
