.fb-share-button > a > svg{
color: #316FF6;
font-size: 25px;
}
.whatsapp-button{
    border: none;
    background-color: transparent;
}
.whatsapp-button > svg{
    color: #075e54;
    font-size: 25px;
}
.fb-button{
    border: none;  
}