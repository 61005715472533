@media screen and (max-width: 900px){
  .all-results{
    margin-top: 10%;
  }
}
@media screen and (min-width: 901px){
  .all-results{
    margin-top: 5%;
  }
}
  


  .all-results h3{
    padding-bottom: 3%;
  }


.portfolio-image:hover{
  transform: scale(1.3);
  transition: transform 1s;
}
div.image-part{
  overflow: hidden;
}
/*style per pagination */
.pageNumber {
  list-style: none;
  display: flex;
}

.pageNumber li {
  padding-top: 5px;

  cursor: pointer;
  width: 7%;
  text-align: center;
  height: 35px;
  margin-left: 3px;
  margin-right: 3px;
  color: #5c7d95;
  font-weight: bold;
}

.pageNumber li:hover {
  background-color: #5c7d95;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.74);
}

.pageNumber li.active {
  color: white;
  font-weight: bold;
  background-color: #5c7d95;
  border-radius: 5px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.pageNumber li button {
  background-color: transparent;
  border: none;
  color: #f26f26;
  font-size: 15px;
  cursor: pointer;
}

ul.pageNumber {
  margin-top: 5%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-bottom: 2%;
}
@media screen and (max-width: 560px) {
  ul.pageNumber {
    width: 100%;
  }
  .all-properties{
    background-color: rgb(241, 241, 241);

  }
}
@media screen and (max-width: 767px) and (min-width: 561px) {
  .all-properties{
    background-color: rgb(241, 241, 241);
 
  }
}
@media screen and (max-width: 920px) and (min-width: 768px) {
  .all-properties{
    background-color: rgb(241, 241, 241);
  
  }
}
@media screen and (min-width: 921px) {
  .all-properties{
    background-color: rgb(241, 241, 241);
  }
}
