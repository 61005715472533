@media screen and (min-width:1200px){
    div.kuadrati-1{
        border: 1px solid #5c7d95;
        opacity: 0.9;
        height: 170px;
        width: 270px;
        border-radius: 12px;
        background-color:#5c7d95;
        margin-bottom: 30px;
    }
    div.kuadrati-2{
        border: 1px solid #5c7d95;
        opacity: 0.9;
        height: 170px;
        width: 270px;
        border-radius: 12px;
        background-color:#5c7d95;
        margin-bottom: 30px;
     
    }
    div.kuadrati-3{
        border: 1px solid #5c7d95;
        opacity: 0.9;
        height: 170px;
        width: 270px;
        border-radius: 12px;
        background-color:#5c7d95;
        margin-bottom: 30px;
        
      
        
    }
    
    div.kuadrati-4{
        border: 1px solid #5c7d95;
        opacity: 0.9;
        height: 170px;
        width: 270px;
        border-radius: 12px;
        background-color: #5c7d95;
        margin-bottom: 30px;
       
    }
    svg.icon-admin{
    
        font-size: 80px;
        color: white;
        opacity: 0.6;
        margin-top: -12px;
        margin-left: 153px;
    }
    svg.icon-adminn{
    
        font-size: 80px;
        color: white;
        opacity: 0.6;
        margin-top: -12px;
        margin-left: 153px;
    }
    .stats-admin{
        padding-top: 4%;
        
        
    }
    div.ngjyra{
        height: 100vh ;
        background-color: rgb(235, 236, 238);
        padding-top: 0%;
       
        
    }

    .numri-res{
        margin-top: 20px;
        font-size: 30px;
        color: white;
        font-weight: 600;
        margin-left: 10px;
    }
    .tre-div.row{
        margin-left: 380px;
    }
    }
      @media screen and (max-width:1199px)
    {
    
        div.kuadrati-1{
            border: 1px solid #5c7d95;
            opacity: 0.9;
            height: 230px;
            width: 220px;
            border-radius: 12px;
            background-color:#5c7d95;
            margin-bottom: 30px;
        }
        div.kuadrati-2{
            border: 1px solid #5c7d95;
            opacity: 0.9;
            height: 230px;
            width: 220px;
            border-radius: 12px;
            background-color:#5c7d95;
            margin-bottom: 30px;
         
        }
        div.kuadrati-3{
            border: 1px solid #5c7d95;
            opacity: 0.9;
            height: 230px;
            width: 220px;
            border-radius: 12px;
            background-color:#5c7d95;
            margin-bottom: 30px;
            
          
            
        }
        
        div.kuadrati-4{
            border: 1px solid #5c7d95;
            opacity: 0.9;
            height: 230px;
            width: 220px;
            border-radius: 12px;
            background-color: #5c7d95;
            margin-bottom: 30px;
           
        }
        svg.icon-admin{
        
            font-size: 80px;
            color: white;
            opacity: 0.6;
            margin-top: 60px;
            margin-left: 130px;
        }
        svg.icon-adminn{
        
            font-size: 80px;
            color: white;
            opacity: 0.6;
            margin-top: 10px;
            margin-left: 130px;
        }

        .tre-div.row{
            margin-left: 280px;
        }
        .stats-admin{
            padding-top: 4%;
            
            
        }
        div.ngjyra{
            height: 100vh ;
            background-color: rgb(235, 236, 238);
            padding-top: 0%;
           
            
        }
        .numri-res{
            margin-top: 20px;
            font-size: 30px;
            color: white;
            font-weight: 600;
            margin-left: 10px;
        }
    }
    @media screen and (max-width: 991px) {
        [data-aos-delay] {
          transition-delay: 0s !important;
        }
      }
.welcome-user{
    text-transform: uppercase;
    margin-left: 380px;
    margin-bottom: 20px;
}