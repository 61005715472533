/* @import url("https://fonts.googleapis.com/css2?family=Belanosima&display=swap");*/

.items {
  margin-right: 1rem;
}

@media screen and (max-width: 600px) and (min-width: 320px) {

  li.items {
    display: inline-block;
    padding: 0px 5px;
    position: relative;
    text-decoration: none;
   letter-spacing: 0.15em;
  }
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  
  li.items {
    display: inline-block;
    padding: 0px 5px;
    position: relative;
    text-decoration: none;
   letter-spacing: 0.15em;
  }
  
}
@media screen and (max-width: 999px) and (min-width: 901px) {
  .menu-but {
    display: none;
  }
  .list {
    display: flex;
  }
  .navbarLinks > img {
    padding-left: 4rem;
  }
  .navbarLinks.contact {
    margin-right: 4rem;
    color: white;
    background-color: black;
    padding: 8% 12%;
  }
  .navbarLinks.contact:hover{
    margin-right: 4rem;
    color: black;
    background-color: white;
    padding: 8% 12%;
    border: 1px solid black;
  }
  .logo img {
    height: 140px; 
  }
  .navbarLinks {
    color:black;
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: "Belanosima", sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }
  li.items {
    display: inline-block;
    position: relative;
    text-decoration: none;
    letter-spacing: 0.1em;
  }
  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    
  }
}
@media screen and (max-width: 1299px) and (min-width: 1000px) {
    .menu-but {
    display: none; /* Hide the button for larger screens */
  }
  .list {
    display: flex; /* Show the navigation links in a row */
  }
  .navbarLinks > img {
    padding-left: 4rem;
  }
  .navbarLinks.contact {
    margin-right: 4rem;
    color: white;
    background-color: black;
    padding: 8% 12%;
  }
  .navbarLinks.contact:hover{
    margin-right: 4rem;
    color: black;
    background-color: white;
    padding: 8% 12%;
    border: 1px solid black;
  }
  .logo img {
    height: 140px; /* Adjust the height of the logo as needed */
  }
  .navbarLinks {
    color:black;
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: "Belanosima", sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }
  li.items {
    display: inline-block;
    padding: 0px 5px;
    position: relative;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    
  }
}

@media screen and (min-width: 1300px) {
  .menu-but {
    display: none;
  }
  .list {
    display: flex;
  }
  .navbarLinks > img {
    padding-left: 4rem;
  }
  .navbarLinks.contact {
    margin-right: 4rem;
    color: white;
    background-color: black;
    padding: 8% 12%;
  }
  .navbarLinks.contact:hover{
    margin-right: 4rem;
    color: black;
    background-color: white;
    padding: 8% 12%;
    border: 1px solid black;
  }
  .logo img {
    height: 180px; 
  }
  .navbarLinks {
    color:black;
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: "Belanosima", sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }
  li.items {
    display: inline-block;
    padding: 0px 5px;
    position: relative;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    
  }
}
.bg-nav{
  width: 100%;
  height: 55vh;
  text-align: center;
  background-image: url(../../images/background2.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  margin-top: 0%;
  overflow: hidden;
  background-position: 50% 100%;
}

