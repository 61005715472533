@media screen and (max-width: 460px) and (min-width: 300px) {
    .signup-container{
        width: 70%;
        height: 480px;
        position: absolute;
        top: 130%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signup-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 1% auto;
    }
    
    .signup-button{
        margin: 3% auto;
        padding: 2% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signup-title{
        margin-top: 3%;
        margin-bottom: 5%;
        text-align: center;
    }
}
@media screen and (max-width: 600px) and (min-width: 461px) {
    .signup-container{
        width: 60%;
        height: 490px;
        position: absolute;
        top: 130%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signup-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 1% auto;
    }
    
    .signup-button{
        margin: 3% auto;
        padding: 2% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signup-title{
        margin-top: 3%;
        margin-bottom: 5%;
        text-align: center;
    }
}
@media screen and (max-width: 730px) and (min-width: 601px) {
    .signup-container{
        width: 50%;
        height: 500px;
        position: absolute;
        top: 130%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signup-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 1% auto;
    }
    
    .signup-button{
        margin: 3% auto;
        padding: 2% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signup-title{
        margin-top: 3%;
        margin-bottom: 5%;
        text-align: center;
    }
}
@media screen and (max-width: 1000px) and (min-width: 731px) {
    .signup-container{
        width: 40%;
        height: 520px;
        position: absolute;
        top: 130%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signup-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 1% auto;
    }
    
    .signup-button{
        margin: 3% auto;
        padding: 2% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signup-title{
        margin-top: 3%;
        margin-bottom: 5%;
        text-align: center;
    }
}
@media screen and (max-width: 1300px) and (min-width: 1001px) {
    .signup-container{
        width: 40%;
        height: 520px;
        position: absolute;
        top: 130%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signup-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 1% auto;
    }
    
    .signup-button{
        margin: 3% auto;
        padding: 2% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signup-title{
        margin-top: 3%;
        margin-bottom: 5%;
        text-align: center;
    }
}
@media screen and (min-width: 1301px){
    .signup-container{
        width: 30%;
        height: 520px;
        position: absolute;
        top: 130%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signup-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 1% auto;
    }
    
    .signup-button{
        margin: 3% auto;
        padding: 2% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signup-title{
        margin-top: 3%;
        margin-bottom: 5%;
        text-align: center;
    }
}
.center-container{
    position: relative;
    height: 100%;
}

.signin{

    color: rgb(148, 148, 250);
}

.errorMessage{
   
    color: red;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
}
.select-role{
    display: block;
    width: 70%;
    padding: 0 12px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px px;
    transition: all 0.3s ease;
    height: 40px;
    line-height: 46px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
    margin: 3% auto;
}
.error-username{
    color: red;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
}