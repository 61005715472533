@media screen and (max-width: 365px) and (min-width: 300px) {
  .bg-services {
    width: 100%;
    height: 55vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 10%;
    overflow: hidden;
    color: white;
    background-position: 60%;
  }
  img.first-service {
    width: 85%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  img.first-service-big {
    width: 85%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 4%;
  }
}

@media screen and (max-width: 450px) and (min-width: 366px) {
  .bg-services {
    width: 100%;
    height: 65vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 8%;
    overflow: hidden;
    color: white;
    background-position: 65%;
  }
  img.first-service {
    width: 85%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  img.first-service-big {
    width: 85%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 4%;
  }
}
@media screen and (max-width: 475px) and (min-width: 451px) {
  .bg-services {
    width: 100%;
    height: 65vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 8%;
    overflow: hidden;
    color: white;
    background-position: 60%;
  }
  img.first-service {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  img.first-service-big {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 4%;
  }
}
@media screen and (max-width: 570px) and (min-width: 476px) {
  .bg-services {
    width: 100%;
    height: 60vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 7%;
    overflow: hidden;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  img.first-service-big {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 7%;
  }
}
@media screen and (max-width: 640px) and (min-width: 571px) {
  .bg-services {
    width: 100%;
    height: 65vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 6%;
    overflow: hidden;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  img.first-service-big {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 8%;
  }
}
@media screen and (max-width: 768px) and (min-width: 641px) {
  .bg-services {
    width: 100%;
    height: 70vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 8%;
    overflow: hidden;

    background-position: 50%;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  img.first-service-big {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin: 8% auto;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 7%;
  }
}
@media screen and (max-width: 850px) and (min-width: 769px) {
  .bg-services {
    width: 100%;
    height: 70vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 8%;
    overflow: hidden;

    background-position: 50%;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 250px;
    object-fit: cover;
    margin: 8% auto;
  }
  img.first-service-big{
    width: 75%;
    height: 250px;
    object-fit: cover;
    margin: 8% auto;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 7%;
  }
}
@media screen and (max-width: 900px) and (min-width: 851px) {
  .bg-services {
    width: 100%;
    height: 80vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-position: 25% 55%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 6%;
    overflow: hidden;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin-top: 8%;  
    margin-bottom:4%;
  }
  img.first-service-big {
    width: 75%;
    height: 220px;
    object-fit: cover;
    margin-top: 8%;  
    margin-bottom:4%;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 7%;
  }
}
@media screen and (max-width: 991px) and (min-width: 901px) {
  .bg-services {
    width: 100%;
    height: 80vh;
    text-align: center;
    background-image: url(../../images/services.png);
    background-position: 25% 55%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 260px;
    object-fit: cover;
    margin-top: 8%;  
    margin-bottom:4%;
  }
  img.first-service-big {
    width: 75%;
    height: 260px;
    object-fit: cover;
    margin-top: 8%;  
    margin-bottom:4%;
  }
  .fist-service-div p {
    text-align: center;
    font-size: 14px;
    padding: 2% 7%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .bg-services {
    width: 100%;
    height: 65vh;
    text-align: center;
    background-image: url(../../images/services.png);
    filter: brightness(70%);
    background-position: 25% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 260px;
    object-fit: cover;
    margin-top: 17%;  
    margin-bottom:4%;
  }
  img.first-service-big {
    width: 90%;
    height: 460px;
    object-fit: cover;
    margin-top: 24%;  
    margin-bottom:4%;
  }
  .fist-service-div p {
    text-align: left;
    font-size: 15px;
    padding: 2% 0%;
  }
  .fist-service-div h4 {
    margin-top: 15%;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1201px) {
  .bg-services {
    width: 100%;
    height: 65vh;
    text-align: center;
    background-image: url(../../images/services.png);
    filter: brightness(70%);
    background-position: 25% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    margin-top: 0%;
    overflow: hidden;
    color: white;
  }
  img.first-service {
    width: 75%;
    height: 260px;
    object-fit: cover;
    margin-top: 17%;  
    margin-bottom:4%;
  }
  img.first-service-big {
    width: 90%;
    height: 460px;
    object-fit: cover;
    margin-top: 24%;  
    margin-bottom:4%;
  }
  .fist-service-div p {
    text-align: left;
    font-size: 17px;
    padding: 2% 0%;
  }
  .fist-service-div h4 {
    margin-top: 12%;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
  }
}

