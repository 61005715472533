@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");

@media screen and (max-width: 450px) and (min-width: 320px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 40%;
  }
  .how-it-work-bg {
    background-image: url("../../images/test6.jpg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 3%;
    font-family: "Dancing Script", cursive;
    color: rgb(88, 88, 88);
    font-size: 35px;
    margin-top: -270px;
  }
  p.how-p {
    text-align: center;
    color: rgb(88, 88, 88);
    padding: 4% 3%;
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media screen and (max-width: 670px) and (min-width: 451px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 20%;
  }
  .how-it-work-bg {
    background-image: url("../../images/test6.jpg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: "Dancing Script", cursive;
    color: rgb(88, 88, 88);
    font-size: 45px;
    margin-top: -290px;
  }
  p.how-p {
    text-align: center;
    color: rgb(88, 88, 88);
    padding: 5% 3%;
    font-size: 17px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}

@media screen and (max-width: 870px) and (min-width: 671px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 15%;
  }
  .how-it-work-bg {
    background-image: url("../../images/test6.jpg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: "Dancing Script", cursive;
    color: rgb(88, 88, 88);
    font-size: 55px;
    margin-top: -310px;
  }
  p.how-p {
    text-align: center;
    color: rgb(88, 88, 88);
    padding: 3% 3%;
    font-size: 19px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media screen and (max-width: 991px) and (min-width: 871px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 15%;
  }
  .how-it-work-bg {
    background-image: url("../../images/test6.jpg");
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: "Dancing Script", cursive;
    color: rgb(88, 88, 88);
    font-size: 55px;
    margin-top: -330px;
  }
  p.how-p {
    text-align: center;
    color: rgb(88, 88, 88);
    padding: 1% 3%;
    font-size: 19px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media screen and (max-width: 1140px) and (min-width: 992px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 12%;
  }
  .how-it-work-bg {
    background-image: url("../../images/test6.jpg");
    width: 100%;
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: "Dancing Script", cursive;
    color: rgb(88, 88, 88);
    font-size: 55px;
    margin-top: -420px;
  }
  p.how-p {
    text-align: center;
    color: rgb(88, 88, 88);
    padding: 1% 3%;
    font-size: 19px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media only screen and (min-width: 1141px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: -45px 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 8%;
  }
  .how-it-work-bg {
    background-image: url("../../images/test6.jpg");
    width: 100%;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: "Dancing Script", cursive;
    color: rgb(88, 88, 88);
    font-size: 55px;
    margin-top: -450px;
    font-weight: 400;
  }
  h2.how-it-work-title {
    font-size: 25px;
  }
  p.how-p {
    text-align: center;
    color: rgb(88, 88, 88);
    padding: 1% 3%;
    font-size: 23px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media only screen and (min-width: 1610px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: -45px 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }
  .how-it-work-bg {
    background-image: url("../../images/5.jpg");
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: "Dancing Script", cursive;
    color: white;
    font-size: 55px;
    margin-top: -470px;
  }
  p.how-p {
    text-align: center;
    color: white;
    padding: 1% 3%;
    font-size: 21px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
@media only screen and (min-width: 1810px) {
  .cards-how-it-work {
    padding: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: #8db8c5;
    transform: translateX(0) rotate(0) scale(0.9);
    background-image: none;
    display: inline-block;
    border: none;
    max-width: 390px;
    margin: -45px 15px;
    cursor: pointer;
    font-size: 18px;
  }
  .card-div {
    left: 0;
    display: table;
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }
  .how-it-work-bg {
    background-image: url("../../images/5.jpg");
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    background-position: 10% 40%;
  }
  h1.how {
    text-align: center;
    padding-top: 10%;
    font-family: "Dancing Script", cursive;
    color: white;
    font-size: 55px;
    margin-top: -520px;
  }
  p.how-p {
    text-align: center;
    color: white;
    padding: 1% 3%;
    font-size: 19px;
    font-weight: 600;
    font-family: "Poppins";
    letter-spacing: 1px;
  }
  .how-it-work-icon {
    width: 60px;
    height: 60px;
    color: white;
  }
}
#how-it-work {
  margin-top: 5%;
  margin-bottom: 10%;
}
