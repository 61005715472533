

span.side-icon{
    color:white;
    margin-right: 10px;
}
span.side-icon:hover{
    color:white;
    opacity: 1;
}
span.arrow-left{
    margin-left: 15px;
    font-size: 25px;
}
@media screen and (max-width: 991px) {
    [data-aos-delay] {
      transition-delay: 0s !important;
    }
  }