@media screen and (max-width: 460px) and (min-width: 300px) {
    .signin-container{
        width: 70%;
        height: 380px;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signin-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 3% auto;
    }
    .signin-button{
        margin: 12% auto;
        padding: 4% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signin-title{
        margin-top: 3%;
        margin-bottom: 8%;
        text-align: center;
    }
}
@media screen and (max-width: 600px) and (min-width: 461px) {
    .signin-container{
        width: 60%;
        height: 350px;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signin-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 3% auto;
    }
    .signin-button{
        margin: 9% auto;
        padding: 3% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signin-title{
        margin-top: 3%;
        margin-bottom: 8%;
        text-align: center;
    }
}
@media screen and (max-width: 730px) and (min-width: 601px) {
    .signin-container{
        width: 50%;
        height: 355px;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signin-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 3% auto;
    }
    .signin-button{
        margin: 8% auto;
        padding: 3% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signin-title{
        margin-top: 3%;
        margin-bottom: 8%;
        text-align: center;
    }
}
@media screen and (max-width: 1000px) and (min-width: 731px) {
    .signin-container{
        width: 40%;
        height: 360px;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signin-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 3% auto;
    }
    .signin-button{
        margin: 7% auto;
        padding: 3% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signin-title{
        margin-top: 3%;
        margin-bottom: 8%;
        text-align: center;
    }
}
@media screen and (max-width: 1300px) and (min-width: 1001px) {

        .signin-container{
            width: 40%;
            height: 380px;
            position: absolute;
            top: 150%;
            left: 50%;
            transform: translate(-50%, 20%);
            text-align: center;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
        .signin-form>input {
            display: block;
            width: 70%;
            padding: 0 12px;
            border: 2px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px px;
            transition: all 0.3s ease;
            height: 40px;
            line-height: 46px;
            outline: none;
            color: #1f1f25;
            font-size: 15px;
            letter-spacing: 0.1px;
            margin: 3% auto;
        }
        
        .signin-button{
            margin: 7% auto;
            padding: 3% 4%;
            color:white;
            background-color: #567189;
            width: 70%;
            border-radius: 5px;
            border: none;
            font-size: 14px;
            letter-spacing: 2px;
        }
        .signin-title{
            margin-top: 3%;
            margin-bottom: 8%;
            text-align: center;
        }
}
@media screen and (min-width: 1301px){
    .signin-container{
        width: 30%;
        height: 440px;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translate(-50%, 20%);
        text-align: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .signin-form>input {
        display: block;
        width: 70%;
        padding: 0 12px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px px;
        transition: all 0.3s ease;
        height: 40px;
        line-height: 46px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        margin: 3% auto;
    }
    
    .signin-button{
        margin: 7% auto;
        padding: 3% 4%;
        color:white;
        background-color: #567189;
        width: 70%;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .signin-title{
        margin-top: 3%;
        margin-bottom: 8%;
        text-align: center;
    }
}

.center-container{
    position: relative;
    height: 100%;
}

.signin{

    color: rgb(148, 148, 250);
}

.errorMessage{
   
    color: red;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
}
