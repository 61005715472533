@media screen and (max-width: 365px) and (min-width: 300px) {
    .rules {
      width: 100%;
      height: 55vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 10%;
      overflow: hidden;
      color: white;
      background-position: 60%;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 16px;
        padding: 0% 3%;
    }
  }
  
  @media screen and (max-width: 450px) and (min-width: 366px) {
    .rules {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 8%;
      overflow: hidden;
      color: white;
      background-position: 65%;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 16px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 475px) and (min-width: 451px) {
    .rules {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 8%;
      overflow: hidden;
      color: white;
      background-position: 60%;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 16px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 570px) and (min-width: 476px) {
    .rules {
      width: 100%;
      height: 60vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 7%;
      overflow: hidden;
      color: white;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 17px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 640px) and (min-width: 571px) {
    .rules {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 6%;
      overflow: hidden;
      color: white;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 17px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 768px) and (min-width: 641px) {
    .rules {
      width: 100%;
      height: 70vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 8%;
      overflow: hidden;
  
      background-position: 50%;
      color: white;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 18px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 850px) and (min-width: 769px) {
    .rules {
      width: 100%;
      height: 70vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 8%;
      overflow: hidden;
  
      background-position: 50%;
      color: white;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 20px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 851px) {
    .rules {
      width: 100%;
      height: 80vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-position: 25% 55%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 6%;
      overflow: hidden;
      color: white;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 20px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 991px) and (min-width: 901px) {
    .rules {
      width: 100%;
      height: 80vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      background-position: 25% 55%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 23px;
        padding: 0% 3%;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .rules {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      filter: brightness(70%);
      background-position: 25% 40%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;
    }
    h4.rule-title{
        margin-top: 5%;
        font-size: 23px;
        padding: 0% 3%;
    }
  }
  @media screen and (min-width: 1201px) {
    .rules {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../images/rules.png);
      filter: brightness(70%);
      background-position: 25% 40%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;
    }
    h4.rule-title{
        margin-top: 2%;
    }
    .rule-div > a{
        font-size: 18px;
      }
  }

  
  