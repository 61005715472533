.property-input{
    width: 50%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    height: 45px;
    margin: 3%;
}
.property-checkbox{
    display: flex;
    margin: 0 5%;
}
.property-textarea{
    width: 50%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    height: 120px;
}
.checkbox-item{
    margin: 2% auto;
}
.form-input{
    display: flex;
}
.form-input-item{
    margin: 2% auto;
}
.create-listing{
    width: 30%;
    height: 40px;
    border: none;
    border-radius: 1px;
    color: white;
    background-color: rgb(39, 133, 156);
    font-size: 18px;
}
.listing-image{
    width: 40%;
    height: 150px;
    object-fit: cover;
    margin-bottom: 2%;
    margin-right: 2%;
}
div.prona-container.container{
    margin: 0% 17%;
}
.add-property{
    text-align: center;
}
.delete-listing-image{
    border: none;
    color: white;
    padding: 2% 4%;
    background-color: black;
    border-radius: 1px;
}
.file-div{
    margin-bottom: 3%;
}