@media screen and (max-width: 460px) and (min-width: 300px) {
    div.card{
        width: 80%;
        margin-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
    }
    div.card.listing-item{
        width: 80%;
        margin-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
    }
    img.card-image{
        width: 100%;
        height: 260px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .best-property-description{
        font-size: 20px;
        padding: 0% 1%;
    }
    .prona-icons svg{
        font-size: 16px;
    }
    .prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
}
@media screen and (max-width: 600px) and (min-width: 461px) {
    div.card{
        width: 90%;
        margin-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
    }
    div.card.listing-item{
        width: 90%;
        margin-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
    }
    img.card-image{
        width: 100%;
        height: 280px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .best-property-description{
        font-size: 20px;
        padding: 0% 1%;
    }
    .prona-icons svg{
        font-size: 17px;
    }
    .prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
}
@media screen and (max-width: 767px) and (min-width: 601px) {
    div.card{
        width: 90%;
        margin-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
    }
    div.card.listing-item{
        width: 90%;
        margin-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
    }
    img.card-image{
        width: 100%;
        height: 280px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .best-property-description{
        font-size: 20px;
        padding: 0% 1%;
    }
    .prona-icons svg{
        font-size: 17px;
    }
    .prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
    div.card{
        width: 95%;
        margin-right: 5%;
        margin-bottom: 3%;
    }
    div.card.listing-item{
        width: 95%;
        margin-right: 5%;
        margin-bottom: 3%;
    }
    img.card-image{
        width: 100%;
        height: 280px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .best-property-description{
        font-size: 20px;
        padding: 0% 1%;
    }
    .prona-icons svg{
        font-size: 17px;
    }
    .prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
}
@media screen and (min-width: 901px) {
    div.card{
        width: 95%;
        margin-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
    }
    div.card.listing-item{
        width: 95%;
        margin-bottom: 3%;
        margin-left: 0;
        margin-right: 0;
        /* margin-left: auto;
        margin-right: auto; */
    }
    img.card-image{
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin: auto;
        position: relative;
    }
    .best-property-description{
        font-size: 22px;
    }
    .prona-icons svg{
        font-size: 18px;
    }
    .prona-icons.price{
        margin-left: 0%;
        margin-right: 0%;
    }
}
img.card-image:hover{
    transform: scale(1.3);
    transition: transform 1s;
}
.card{
    overflow: hidden;
}
.prona-type2{
    padding: 8px;
    background-color: black;
    color: white;
    width: 30%;
    position: absolute;
    z-index: 1;
    text-transform: capitalize;
}
.best-property-id{
    font-weight: 500;
    font-size: 17px;
    text-align: left;
    padding: 2px 10px;
}
.prona-icons{
    margin-right: 10%;
}

